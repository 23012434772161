import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: '/inicio', pathMatch: 'full'},
  {
    path: 'inicio',
    loadChildren: './components/inicio/inicio.module#InicioModule',
  },
  {
    path: 'naves-industriales',
    loadChildren: './components/naves-industriales/naves-industriales.module#NavesIndustrialesModule',
  },
  {
    path: 'parques-industriales',
    loadChildren: './components/parques-industriales/parques-industriales.module#ParquesIndustrialesModule',
  },
  {
    path: 'aliados',
    loadChildren: './components/aliados/aliados.module#AliadosModule',
  },
  {
    path: 'acercade',
    loadChildren: './components/acercade/acercade.module#AcercadeModule',
  },
  {
    path: 'detalle',
    loadChildren: './components/detalle/detalle.module#DetalleModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload',scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
