import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InmueblesService } from '../../services/inmuebles.service';
import AOS from 'aos';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public urlPdfAviso = null;
  public form: FormGroup;
  constructor(private fb: FormBuilder, private _inmuebles: InmueblesService) {
  }


  ngOnInit() {
    this.form = this.fb.group({
      nombre: ['', Validators.required],
      telefono: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      mensaje: ['', Validators.required],
    });
    AOS.init();
    this._inmuebles.getPdfAviso()
    .subscribe(pdfAviso => {
      this.urlPdfAviso = pdfAviso
    });    
  }

  enviarContacto() {
    let form: any = JSON.stringify(this.form.value);
    try {
      if(!this.form.value.nombre) { throw "Ingresa tu nombre"; }
      if(!this.form.value.telefono) { throw "Ingresa tu teléfono"; }
      if(!this.form.value.correo) { throw "Ingresa tu correo"; }
      if(!this.form.value.mensaje) { throw "Ingresa tu mensaje o duda"; }

      this._inmuebles.enviarFormulario(form)
      .subscribe(res => {
        this._inmuebles.showToastr('success', 'Se ha enviado tu solicitud, nos pondremos en contacto contigo.');
        this.form.reset({
          nombre: '',
          telefono: '',
          correo: '',
          mensaje: ''
        });
      }, err => this._inmuebles.showToastr('error', err));
    } catch(err) {
      this._inmuebles.showToastr('error', err);
    }
  }
    verPdfAviso() {
    setTimeout(() => {
      let nombre = `aviso_privacidad.pdf`;
      fetch(this.urlPdfAviso)
      .then(res => res.blob()) 
    .then((blob: any) => {
      let objectURL = URL.createObjectURL(blob);
      if (this.urlPdfAviso.length > 0) {
        window.open(this.urlPdfAviso)
      }
    })
  },500)
}

  scroll(id){
    const elmnt = document.getElementById(id);
    elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
