import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapaComponent } from './mapa/mapa.component';
import { AgmCoreModule } from '@agm/core';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MapaComponent, NavbarComponent, FooterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCOXOZhceIZCQDctLBWWp7CHlyTweJee8Q'
    }),
  ], exports: [
    MapaComponent,
    NavbarComponent,
    FooterComponent
  ]
})
export class PartialsModule { }
