import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    var  tb = $('.scrollcolor');
    let tbs = "change-nav";
    $(window).scroll(function() {
    if($(this).scrollTop()) {
        tb.addClass(tbs);
        $('.menu.fixed-top').css('height','100px');
        $('.menu').css('background-color','rgba(12,68,107,1)');
        $('.img-top').css('width','6rem');
        $('.img-top').css('padding-top','0rem');
        $('.form-inline').css('position','absolute');
        $('.form-inline').css('top','0rem');
        $('.form-inline').css('display','flex');
        $('.form-inline').css('width','100%');
        $('.form-inline').css('left','75%');
        $('.itemTop').css('height','0px');
        $('.itemTop').css('position','relative');
        $('.itemTop').css('top','-1rem');
        $('.title-top').css('width','9rem');
        $('.ul.navbar-nav li.nav-item > a.nav-link').css('font-size','10px');
        $('.navbar-brand').css('height','3rem');
        $('.itemTop').css('height','0px');
        $('.collapse.navbar-collapse, .navbar-brand').css('padding-top','.3rem');
        /* $('.scrollcolor').css('margin-top','0rem'); */
        $('.slide').css('margin-top','6rem');
        $('.menuTwo').css('top','5.8rem');
    } else {
        tb.removeClass(tbs);
        $('.menu.fixed-top').css('height','');
        $('.menu').css('background-color','');
        $('.img-top').css('width','');
        $('.img-top').css('padding-top','');
        $('.form-inline').css('position','');
        $('.form-inline').css('top','');
        $('.form-inline').css('display','');
        $('.form-inline').css('width','');
        $('.form-inline').css('left','');
        $('.itemTop').css('height','');
        $('.itemTop').css('position','');
        $('.itemTop').css('top','');
        $('.form-inline').css('display','');
        $('.ul.navbar-nav li.nav-item > a.nav-link').css('font-size','');
        $('.img-top').css('width','');
        $('.navbar-brand').css('height','');
        $('.itemTop').css('height','');
        /* $('.scrollcolor').css('margin-top',''); */
        $('.collapse.navbar-collapse, .navbar-brand').css('padding-top','');
        $('.slide').css('margin-top','');
        $('.menuTwo').css('top','');

    }
    });
  }
  scroll(id){
    this.router.navigate(['/inicio']);
    setTimeout(() => {
      const elmnt = document.getElementById(id);
      elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 200);
  }

}
