import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Globals } from '../../globals';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Portada } from '../interfaces/portadas.interfaces';

@Injectable({
  providedIn: 'root'
})
export class InmueblesService {
  urlPdfAviso = null;
  portada : any;
  aliado  : any;
  aliados : string [] = [];
  public caracteristicas = [
    {txt: 'Acceso controlado 24/7',txt2:'sub descripción', valor: 'acceso24/7',icon: 'assets/custom/imgs/acceso.svg'},
    {txt: 'Alumbrado público tipo led',txt2:'sub descripción', valor: 'alumbrado_led',icon: 'assets/custom/imgs/iluminacion.svg'},
    {txt: 'Área de espera',txt2:'sub descripción', valor: 'area_espera',icon: 'assets/custom/imgs/area-de-espera.svg'},
    {txt: 'Área verde',txt2:'sub descripción', valor: 'area_verde',icon: 'assets/custom/imgs/areas-verdes.svg'},
    {txt: 'Bascula para camiones',txt2:'sub descripción', valor: 'bascula',icon: 'assets/custom/imgs/bascula-para-camiones.svg'},
    {txt: 'Centro de negocios',txt2:'sub descripción', valor: 'centro_negocios',icon: 'assets/custom/imgs/centro-de-negocios.svg'},
    {txt: 'Drenaje pluvial',txt2:'sub descripción', valor: 'drenaje_pluvial',icon: 'assets/custom/imgs/drenaje-pluvial.svg'},
    {txt: 'Drenaje sanitario',txt2:'sub descripción', valor: 'deranje_sanitario',icon: 'assets/custom/imgs/drenaje.svg'},
    {txt: 'Energía eléctrica línea de media tensión',txt2:'sub descripción', valor: 'energia_media_tension',icon: 'assets/custom/imgs/energia-linea-media.svg'},
    {txt: 'Oficinas Administrativas',txt2:'sub descripción', valor: 'oficinas_admin',icon: 'assets/custom/imgs/oficina-administrativa.svg'},
    {txt: 'Telecomunicaciones (voz y datos)',txt2:'sub descripción', valor: 'telecomunicaciones',icon: 'assets/custom/imgs/telecomunicaciones.svg'},
    {txt: 'Terracería tratada',txt2:'sub descripción', valor: 'terraceria_tratada',icon: 'assets/custom/imgs/terraceria-tratada.svg'},
    {txt: 'Sección aduanera interior',txt2:'sub descripción', valor: 'seccion_aduanera',icon: 'assets/custom/imgs/seccion-aduanera-interior.svg'},
    {txt: 'Sistema contra incendio',txt2:'sub descripción', valor: 'sistema_incendios',icon: 'assets/custom/imgs/incendios.svg'},
    {txt: 'Subestación eléctrica',txt2:'sub descripción', valor: 'subestacion_electrica',icon: 'assets/custom/imgs/subestacion.svg'},
    {txt: 'Vialidades de concreto hidráulico',txt2:'sub descripción', valor: 'vialidades_concreto',icon: 'assets/custom/imgs/vialidades.svg'},
    {txt: 'Volcador para camiones',txt2:'sub descripción', valor: 'volcador',icon: 'assets/custom/imgs/volcador-para-camiones.svg'},
  ];
  constructor(private http: HttpClient, private globals: Globals, private toastr: ToastrManager) { }
  getAllInmuebles(tipo) {
    return this.http.get(`${this.globals.apiUrl}/almacenes/getAll?tipo=${tipo}`).pipe( catchError(this.handleError));
  }
  getInmueble(nombre,tipo) {
    return this.http.get(`${this.globals.apiUrl}/almacenes/getAlmacenDetalle?nombreAlmacen=${nombre}&tipo=${tipo}`).pipe( catchError(this.handleError));
  }
  enviarFormulario(info) {
    return this.http.post(`${this.globals.apiUrl}/contacto/enviarContacto`, info).pipe( catchError(this.handleError));
  }
  showToastr(tipo,mensaje) {
    return tipo === 'error' ? this.toastr.errorToastr(mensaje, '', {toastTimeout: 2000,maxShown: 1, position: 'bottom-right'}) :
    this.toastr.successToastr(mensaje, '', {toastTimeout: 2000,maxShown: 1, position: 'bottom-right'});
  }
  getAllSlides() {
    return this.http.get(`${this.globals.apiUrl}/slides/getAll`).pipe( catchError(this.handleError));
  }
  getAllPortadas(seccion: string) {
    return this.http.get<Portada[]>(`${this.globals.apiUrl}/portadas/getAll`)
    .pipe( 
      map(res=> {
          this.portada = res.find((portada)=> portada.seccion == seccion);
          if (this.portada.archivos.length > 0) {
            this.portada = `${this.globals.apiUrl}/UploadedFiles/getUploadedFiles?uuid=${this.portada.archivos[0].uuid}&id=${this.portada.id!}&tipo=portada&random=${Math.floor(Math.random()*1000)}`
          }
          return this.portada.toString();
        }),
      catchError(this.handleError)
    );
  }

  getPdfAviso() {
    return this.http.get(`${this.globals.apiUrl}/UploadedPdf/getPdfAviso`)
      .pipe(
        map(resp => {
          if (!resp) {
            return ''
          }
            let pdf = resp
            this.urlPdfAviso = pdf ?  `${this.globals.apiUrl}/UploadedPdf/getUploadedPdf?uuid=${pdf[0].uuid}&random=${Math.floor(Math.random()*1000)}` : '';
          return this.urlPdfAviso
        }),
      catchError(this.handleError)
    )
  }

  getAllAliados() {
    return this.http.get(`${this.globals.apiUrl}/aliados/getAll`)
    .pipe(
      map( aliados => {
        this.aliados = [];
        this.aliado = aliados;
        this.aliado.forEach(al => {
          if (al.archivos.length > 0) {
            let img = `${this.globals.apiUrl}/UploadedFiles/getUploadedFiles?uuid=${al.archivos[0].uuid}&id=${al.id!}&tipo=aliado&random=${Math.floor(Math.random()*1000)}`
            this.aliados.push(img)
          }
          });
          return this.aliados
      }),
        catchError(this.handleError)
      ) 
  }

  getAllFiles() {
    return this.http.get(`${this.globals.apiUrl}/UploadedPdf/getUploadedPdf`).pipe(catchError(this.handleError));
  }
  
  getAllNoticias() {
    return this.http.get(`${this.globals.apiUrl}/noticias/getAll`).pipe( catchError(this.handleError));
  }
  generarCaracteristicas(caracteristicas) {
    let caract = [];
        caracteristicas.forEach(caracteristica => {
          let caracteristicaPar = this.caracteristicas.find(c => c.valor === caracteristica);
          caract.push(caracteristicaPar);
        });
        return caract;
  }
   public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };

  scrollTo(top: number){
    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    });
  }
}
